import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/pengaturan/user',
        component: () => import('@/layout/SettingUserLayout.vue'),
        redirect: { name: 'UserView' },
        children: [
            {
                path: '',
                name: 'UserView',
                component: () => import('@/views/UserView.vue'),
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
